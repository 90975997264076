import { useContext, useState } from 'react';

import {
  alpha,
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  ListSubheader,
  styled
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import SettingsIcon from '@mui/icons-material/Settings';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(0)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(0)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[100]};
      padding: ${theme.spacing(0, 0)};
      line-height: 1;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(14)};
            text-transform: uppercase;
            color: ${theme.palette.primary.light};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(0, 3)};
          font-size: ${theme.typography.pxToRem(14)};
          font-weight: 200;

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(18)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(18)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(5)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 2)};

              .MuiBadge-root {
                right: ${theme.spacing(2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const SidebarMenu = () => {
  const gdata = useSelector((state: RootState) => state.gdata);
  const { closeSidebar } = useContext(SidebarContext);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  const handleClick = () => {
    setOpen(!open);

    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
  };

  const handleClick1 = () => {
    setOpen1(!open1);

    setOpen(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
  };

  const handleClick2 = () => {
    setOpen2(!open2);

    setOpen(false);
    setOpen1(false);
    setOpen3(false);
    setOpen4(false);
  };

  const handleClick3 = () => {
    setOpen3(!open3);

    setOpen(false);
    setOpen1(false);
    setOpen2(false);
    setOpen4(false);
  };

  const handleClick4 = () => {
    setOpen4(!open4);

    setOpen(false);
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
  };
  let svgIcon = <SettingsIcon />;

  return (
    <>
      <MenuWrapper>
        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/"
                  startIcon={<DesignServicesTwoToneIcon />}
                >
                  Dashboards
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component="div"
          subheader={
            <Button
              onClick={handleClick}
              endIcon={open ? <ExpandLess /> : <ExpandMore />}
            >
              <ListSubheader component="div" disableSticky>
                Admin
              </ListSubheader>
            </Button>
          }
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <SubMenuWrapper>
              <List component="div">
                {gdata.menu
                  ?.filter((v) => v.mn_parent === 1)
                  .map((e, i) => {
                    let svgIcon = <SettingsIcon />;

                    return (
                      <ListItem component="div" key={i}>
                        <Button
                          key={i}
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to={e.mn_link}
                          startIcon={svgIcon}
                        >
                          {e.mn_name}
                        </Button>
                      </ListItem>
                    );
                  })}
              </List>
            </SubMenuWrapper>
          </Collapse>
        </List>
        <List
          component="div"
          subheader={
            <Button
              onClick={handleClick1}
              endIcon={open1 ? <ExpandLess /> : <ExpandMore />}
            >
              <ListSubheader component="div" disableSticky>
                Master
              </ListSubheader>
            </Button>
          }
        >
          <Collapse in={open1} timeout="auto" unmountOnExit>
            <SubMenuWrapper>
              <List component="div">
                {gdata.menu
                  ?.filter((v) => v.mn_parent === 2)
                  .map((e, i) => {
                    let svgIcon = <AdminPanelSettingsIcon />;

                    return (
                      <ListItem component="div" key={i}>
                        <Button
                          key={i}
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to={e.mn_link}
                          startIcon={svgIcon}
                        >
                          {e.mn_name}
                        </Button>
                      </ListItem>
                    );
                  })}
              </List>
            </SubMenuWrapper>
          </Collapse>
        </List>
        <List
          component="div"
          subheader={
            <Button
              onClick={handleClick2}
              endIcon={open2 ? <ExpandLess /> : <ExpandMore />}
            >
              <ListSubheader component="div" disableSticky>
                Transaction
              </ListSubheader>
            </Button>
          }
        >
          <Collapse in={open2} timeout="auto" unmountOnExit>
            <SubMenuWrapper>
              <List component="div">
                {gdata.menu
                  ?.filter((v) => v.mn_parent === 3)
                  .map((e, i) => {
                    let svgIcon = <ApartmentIcon />;

                    return (
                      <ListItem component="div" key={i}>
                        <Button
                          key={i}
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to={e.mn_link}
                          startIcon={svgIcon}
                        >
                          {e.mn_name}
                        </Button>
                      </ListItem>
                    );
                  })}
              </List>
            </SubMenuWrapper>
          </Collapse>
        </List>
        <List
          component="div"
          subheader={
            <Button
              onClick={handleClick3}
              endIcon={open3 ? <ExpandLess /> : <ExpandMore />}
            >
              <ListSubheader component="div" disableSticky>
                Entry
              </ListSubheader>
            </Button>
          }
        >
          <Collapse in={open3} timeout="auto" unmountOnExit>
            <SubMenuWrapper>
              <List component="div">
                {gdata.menu
                  ?.filter((v) => v.mn_parent === 4)
                  .map((e, i) => {
                    let svgIcon = <RoomPreferencesIcon />;

                    return (
                      <ListItem component="div" key={i}>
                        <Button
                          key={i}
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to={e.mn_link}
                          startIcon={svgIcon}
                        >
                          {e.mn_name}
                        </Button>
                      </ListItem>
                    );
                  })}
              </List>
            </SubMenuWrapper>
          </Collapse>
        </List>
        <List
          component="div"
          subheader={
            <Button
              onClick={handleClick4}
              endIcon={open4 ? <ExpandLess /> : <ExpandMore />}
            >
              <ListSubheader component="div" disableSticky>
                Reports
              </ListSubheader>
            </Button>
          }
        >
          <Collapse in={open4} timeout="auto" unmountOnExit>
            <SubMenuWrapper>
              <List component="div">
                {gdata.menu
                  ?.filter((v) => v.mn_parent === 5)
                  .map((e, i) => {
                    let svgIcon = <SummarizeIcon />;

                    return (
                      <ListItem component="div" key={i}>
                        <Button
                          key={i}
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to={e.mn_link}
                          startIcon={svgIcon}
                        >
                          {e.mn_name}
                        </Button>
                      </ListItem>
                    );
                  })}
              </List>
            </SubMenuWrapper>
          </Collapse>
        </List>
      </MenuWrapper>
    </>
  );
};

export default SidebarMenu;
