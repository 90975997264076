import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';

import { companyApi } from '../services/acompanyApi';
import { countryApi } from '../services/acountryApi';
import { stateApi } from '../services/astateApi';
import { usersApi } from '../services/ausersApi';
import { ausermenuApi } from '../services/ausermenuApi';
import { authSlice } from '../services/authSlice';

import { dashboardApi } from '../services/dashboardApi';
import { auditApi } from '../services/auditApi';

import { amenuApi } from '../services/amenuApi';
import { modeApi } from '../services/amodeApi';

import { crasettingsApi } from '../services/crasettingsApi';

import { crmcurrencyApi } from '../services/crmcurrencyApi';
import { crmcustomerApi } from '../services/crmcustomerApi';
import { crmacheadApi } from '../services/crmacheadApi';
import { crepurchaseApi } from '../services/crepurchaseApi';
import { cresalesApi } from '../services/cresalesApi';
import { crereceiptApi } from '../services/crereceiptApi';
import { crepaymentApi } from '../services/crepaymentApi';
import { creexpensesApi } from '../services/creexpensesApi';
import { creincomesApi } from '../services/creincomesApi';

import { crreportsApi } from '../services/crreportsApi';

import notification from '../../components/Notification';
import { logicSlice } from '../services/agen';
import Logout from 'src/screens/auth/Logout';

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      switch (action.payload.originalStatus) {
        case 500: {
          notification.error({
            title: 'Server Error',
            message: action.payload.data
          });
          break;
        }
        case 400: {
          notification.error({
            title: 'Error',
            message: action.payload.data
          });
          break;
        }
        case 401: {
          notification.error({
            title: 'Error',
            message: action.payload.data
          });
          console.log('LogOut');
          Logout();
          break;
        }
        case 301: {
          notification.error({
            title: 'Error',
            message: action.payload.data
          });
          break;
        }
        case 2001: {
          notification.success({
            title: 'Sucess',
            message: action.payload.data
          });
          break;
        }
      }
    }

    return next(action);
  };

const rootReducer = combineReducers({
  [usersApi.reducerPath]: usersApi.reducer,
  [ausermenuApi.reducerPath]: ausermenuApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [countryApi.reducerPath]: countryApi.reducer,
  [stateApi.reducerPath]: stateApi.reducer,
  [amenuApi.reducerPath]: amenuApi.reducer,
  [modeApi.reducerPath]: modeApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [auditApi.reducerPath]: auditApi.reducer,
  [crasettingsApi.reducerPath]: crasettingsApi.reducer,
  [crmcurrencyApi.reducerPath]: crmcurrencyApi.reducer,
  [crmcustomerApi.reducerPath]: crmcustomerApi.reducer,
  [crmacheadApi.reducerPath]: crmacheadApi.reducer,
  [crepurchaseApi.reducerPath]: crepurchaseApi.reducer,
  [cresalesApi.reducerPath]: cresalesApi.reducer,
  [crereceiptApi.reducerPath]: crereceiptApi.reducer,
  [crepaymentApi.reducerPath]: crepaymentApi.reducer,
  [creexpensesApi.reducerPath]: creexpensesApi.reducer,
  [creincomesApi.reducerPath]: creincomesApi.reducer,
  [crreportsApi.reducerPath]: crreportsApi.reducer,

  gdata: authSlice.reducer,
  glogic: logicSlice.reducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddiware) =>
    getDefaultMiddiware().concat(
      rtkQueryErrorLogger,
      usersApi.middleware,
      ausermenuApi.middleware,
      companyApi.middleware,
      countryApi.middleware,
      stateApi.middleware,
      amenuApi.middleware,
      modeApi.middleware,
      dashboardApi.middleware,
      auditApi.middleware,
      crasettingsApi.middleware,
      crmcurrencyApi.middleware,
      crmcustomerApi.middleware,
      crmacheadApi.middleware,
      crepurchaseApi.middleware,
      cresalesApi.middleware,
      crereceiptApi.middleware,
      crepaymentApi.middleware,
      creexpensesApi.middleware,
      creincomesApi.middleware,
      crreportsApi.middleware
    ),
  devTools: true
});

export default store;
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
